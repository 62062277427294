<!--
 * @Date: 2024-08-28 16:20:34
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-11 11:05:43
 * @FilePath: \zyt-mobile-frontend\src\views\me\representApproval\detail.vue
-->
<template>
  <div class="detail-container">
    <van-nav-bar title="放映报告审批" left-arrow @click-left="$router.back()" />
    <!-- 基本信息 -->
    <van-cell-group title="基本信息">
      <van-cell title="报告编号">
        <template #label>
          {{ report.id }}
        </template>
      </van-cell>

      <van-cell
        title="放映影片"
        :label="report.films.map((r) => r.name).join('/')"
      />
      <van-cell
        title="影片类型"
        :label="report.films.map((r) => r.type).join('/')"
      />
      <van-cell title="放映时间" :label="report.playDate" />
      <van-cell title="观影人数">
        <template #label>
          {{ report.attendanceNum | countFormat }}
        </template>
      </van-cell>
      <van-cell title="放映地点">
        <template #label>
          <div>
            {{ report.remark.detailAddress }}
          </div>
        </template>
      </van-cell>

      <van-cell title="经纬度" :label="report.lat + ',' + report.lng" />
      <van-cell title=" 放映解码卡号" :label="report.cinemaCompanyCardCode" />
      <van-cell title="放映员姓名" :label="report.filmTeam.realName" />
      <van-cell title="放映员手机号" :label="report.phone" />
    </van-cell-group>
    <van-cell-group title="现场照片">
      <van-cell title="">
        <template #label style="display: flex; flex-wrap: wrap">
          <van-image
            v-for="(photo, index) in report.pictures"
            style="width: 40vw; margin-right: 5vw"
            :key="index"
            :src="photo"
            fit="contain"
          />
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="数据核验结果">
      <van-cell >
        <template #label>
          <!-- :label="report.filmLogs.map((r) => r.filmName).join('/')" -->
          {{ report.filmLogs?report.filmLogs.map((r) => r.filmName).join('/'):'无数据' }}
        </template>
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>放映影片</span>
          </div>
        </template>
      </van-cell>
      <van-cell :label="report.filmLogs.map((r) => r.filmstyle).join('/')">
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>影片类型</span>
          </div>
        </template>
      </van-cell>
      <van-cell
        :label="report.filmLogs.map((r) => r.playStartTime).join(' / ')"
      >
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>放映时间</span>
          </div>
        </template>
      </van-cell>
      <van-cell :label="report.showPoint.address">
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>放映地点</span>
          </div>
        </template>
      </van-cell>
      <van-cell :label="report.lat + ',' + report.lng">
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>经纬度</span>
          </div>
        </template>
      </van-cell>
      <!-- 最后一条 -->
      <van-cell :label="report.filmLogs">
        <template #label>
          {{ getLast(report.filmLogs).cinemaCompanyCardCode }}
        </template>
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span> 放映解码卡号</span>
          </div>
        </template>
      </van-cell>
      <!-- 定位距离 -->
      <van-cell>
        <template #title>
          <div style="display: flex; align-items: baseline">
            <van-icon
              name="checked"
              :color="
                getLast(report.filmLogs).effective ? '#67c23a' : '#ff6a2a'
              "
              size="16"
            />
            <span>定位距离</span>
          </div>
        </template>
        <template #label>
          距离放映点：{{ (report.showPoint?.meter / 1000).toFixed(2) }}km /
          距离放映设备：{{
            (getLast(report.filmLogs)?.meter / 1000).toFixed(2)
          }}km
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 审核结果 -->

    <van-cell-group title="审核结果">
      <van-cell title="有效影片">
        <template #label>
          {{ report.filmLogs.filter((r) => r.effective).length }}
        </template>
      </van-cell>
      <van-cell title="无效影片">
        <template #label>
          {{ report.filmLogs.filter((r) => !r.effective).length }}
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 签字 -->
    <van-cell-group title="签字">
      <!-- <van-cell title="放映员签字">
        <template #label>
          <div class="signature-section">
            <div>
              <img
                class="signature-img"
                v-if="report.signature"
                :src="report.signature"
                style="width: 80%"
                alt="签字"
              />
              <span class="signature-box" v-else>
                <van-icon name="edit" size="40" />
              </span>
            </div>
            <div class="signature-time">
              <span>{{ new Date().toLocaleString() }}</span>
            </div>
          </div>
        </template>
      </van-cell> -->
      <pre v-isDev>
        <!-- {{ report.audit }} -->
        {{ getLevel(report.audit,6) }}
      </pre>
      <van-cell title="集体代表签字">
        <template #label>
          <div class="signature-section" v-if="isDetail">
            <div>
              <img
                class="signature-img"
                :src="getLevel(report.audit, 6)?.auditSignature"
                style="max-width: 50vw"
                alt="签字"
              />
              <!-- <img class="signature-img"  v-else
              :src="getLevel(report.audit,6)?.auditSignature" style="max-width: 50vw" alt="签字"> -->
              <!-- <span class="signature-box" v-else>
                <van-icon name="edit" size="40" @click="showSign = true" />
              </span> -->
            </div>
            <div class="signature-time">
              <span style="z-index: 10">{{
                getLevel(report.audit, 6)?.operateTime
              }}</span>
            </div>
          </div>
          <div class="signature-section" v-else>
            <img
              class="signature-img"
              v-if="report.representSignature"
              :src="report.representSignature"
              style="max-width: 50vw"
              alt="签字"
            />
            <van-icon name="edit" size="40" @click="showSign = true" v-else />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="box-button" v-if="!isDetail">
      <van-button
        round
        type="info"
        size="large"
        readonly
        style="width: 90%"
        @click="confirmApproval"
        color="#3d8bff"
        >提交</van-button
      >
    </div>
    <sign-canvas
      v-if="!isDetail"
      :show="showSign"
      :report="report"
      @cancelHandler="showSign = false"
      format="image/png"
      bgColor="rgb(255,255,255)"
      :quality="0.8"
      @sureHandler="confirmSign"
    ></sign-canvas>
  </div>
</template>

<script>
import SignCanvas from '@/components/SignCanvas/index'
import { api } from '@/api'
import { Dialog, Toast } from 'vant'
import config from '@/config'

export default {
  components: {
    SignCanvas,
  },
  data() {
    return {
      reportId: null,
      showSign: false,
      isDetail: false,
      report: {
        id: 2024080801,
        attendanceNum: 100,
        showTime: '2024-08-08 16:00:00',
        count: '10-20',
        address: '上海市闵行区莘庄镇莘松路888号',
        lat: 31.16222,
        lng: 121.39111,
        //  放映解码卡号
        deviceCode: '123456',
        filmTeam: {
          realName: '张三',
        },
        films: [
          {
            name: '《盗墓笔记》',
            type: '剧情',
          },
        ],
        showPoint: {
          address: '上海市闵行区莘庄镇莘松路888号',
          meter: 10000,
        },
        audit: [],
        playDate: '2024-08-08 16:00:00',
        //  放映解码卡号
        cinemaCompanyCardCode: '123456',
        // 放映员姓名
        operatorName: '张三',
        // 放映员手机号
        operatorPhone: '13800138000',
        // 现场照片
        pictures: [],
        filmLogs: [
          {
            filmName: '《盗墓笔记》',
            filmstyle: '故事片',
            playStartTime: '2024-08-08 16:00:00',
            effective: true,
            meter: 10000,
            cinemaCompanyCardCode: '123456',
          },
        ],
        // 现场照片
        photos: [],
        remark: {
          detailAddress: '上海市闵行区莘庄镇莘松路888号',
        },
        // 距离放映点距离
        distance: '100',
        // 距离放映定位
        distanceToShow: '1233',
        // 签字图
        signature: '',
      },
    }
  },
  mounted() {
    this.reportId = this.$route.params.id
    this.isDetail = this.$route.query.detail === 'true'
    console.log({ isDetail: this.isDetail , reportId: this.reportId})
    this.getReportDetail()
  },
  methods: {
    // 获取放映报告明细
    getReportDetail() {
      api.filmmaker.getReportDetail({ id: this.reportId }).then((res) => {
        const { success, result } = res
        if (success) {
          this.report = result
          this.report.filmLogs=result.filmLogs?result.filmLogs:[]
          this.report.remark = JSON.parse(result.remark)
        }
      })
    },
    confirmApproval() {
      if (this.report.representSignature) {
        this.showSign = false
        Dialog.confirm({
          message: '确认提交放映报告？',
          callback: (action) => {
            if (action === 'confirm') {
              api.groupRepresent
                .submitReport({
                  id: this.reportId,
                  signature: this.report.representSignature,
                })
                .then((res) => {
                  const { success, message } = res
                  if (success) {
                    // Toast.success('提交成功')
                    Dialog.alert({
                      message: '提交成功!',
                      callback: () => {
                        this.$router.go(-1)
                      },
                    })

                    // this.$router.push({ name: 'Me' })
                  } else {
                    Toast.fail(message)
                  }
                })
            }
          },
        })
      } else {
        Dialog.alert({
          message: '请填写签字。',
          callback: () => {
            this.showSign = true
          },
        })
      }
    },
    confirmSign(data) {
      this.showSign = false
      this.report.representSignature = data
      console.log(data)
    },
    // 返回数组最后一个元素
    getLast(arr) {
      if (!arr || arr.length === 0) {
        return {}
      }
      return arr[arr.length - 1]
    },
    // 获取audit等级level
    getLevel(audits, level) {
      return audits.filter((r) => r.level === level)[0]
    },
  },
  filters: {
    // 观影人数
    countFormat(value) {
      console.log({ value })
      try {
        return (
          config.audienceRange.filter((r) => r.value === parseInt(value))[0]
            .text || value
        )
      } catch (error) {
        return value
      }
    },
  },
}
</script>

<style scoped lang="less">
.detail-container {
  padding-bottom: 5vh;
}
.report-info {
  margin-bottom: 20px;
}
.box-button {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 0;
}
.signature-section {
  margin-bottom: 20px;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: space-around;

  & > div {
    flex: 1;
    text-align: center;
  }
  & > .signature-time {
    display: block;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    color: #686b73;
    font-size: 16px;
    z-index: 99;
    &::before {
      content: '';
      background: url('https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d10ac456c.png')
        no-repeat center center;
      opacity: 0.8;
      background-size: contain;
      display: inline-block;
      width: 100%;
      height: 10vh;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
    }
  }
}
</style>
